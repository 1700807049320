import * as React from 'react';
import { render } from 'react-dom';

const rootElem = document.getElementById('root');
import 'normalize.css';
import './styles/index.css';

import App from './App';

render(<App />, rootElem);
