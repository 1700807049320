import * as React from 'react';
import {
  FormGroup,
  TextArea,
  Intent,
  Button,
  Classes
} from '@blueprintjs/core';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  text-align: center;
`;

const TextInput: React.StatelessComponent<{
  onChange: (text: string) => void,
  onSubmit: () => void,
  text: string,
  loading: boolean,
}> = ({ text, loading, onChange, onSubmit }) => {
  return (
    <div>
      <FormGroup>
        <TextArea
          intent={Intent.PRIMARY}
          fill
          rows={5}
          onChange={(e) => onChange(e.currentTarget.value)}
          value={text}
        />
      </FormGroup>
      <ButtonWrapper>
        <Button
          type="button"
          loading={loading}
          className={Classes.LARGE}
          intent={Intent.PRIMARY}
          text="やさしくなーれ"
          onClick={() => onSubmit()}
        />
      </ButtonWrapper>
    </div>
  );
};

export default TextInput;