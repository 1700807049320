import * as React from 'react';
import { Result } from '../models/result';
import SentenceCard from './SentenceCard';

const ResultList: React.StatelessComponent<{
  result: Result;
}> = ({ result }) => {
  return (
    <div>
      {result.sentences.map(sentence => <SentenceCard sentence={sentence} />)}
    </div>
  );
};

export default ResultList;
