import * as React from 'react';

import {
  Navbar,
  NavbarGroup,
  NavbarHeading,
  Classes,
  Alignment,
  Intent,
  TextArea,
  Button,
  ButtonGroup,
  FormGroup
} from '@blueprintjs/core';
import WidthCap from './components/WidthCap';
import TextInput from './components/TextInput';
import ResultList from './components/ResultList';
import { Result } from './models/result';

const Main = WidthCap.extend`
  margin-top: 24px;
`;

export default class App extends React.Component<{}> {
  public state = {
    result: null as Result | null,
    loading: false,
    text: ''
  };

  render() {
    const { result, loading, text } = this.state;
    return (
      <div>
        <Navbar>
          <WidthCap>
            <NavbarGroup align={Alignment.LEFT}>
              <NavbarHeading>やさしくなーれ</NavbarHeading>
            </NavbarGroup>
          </WidthCap>
        </Navbar>
        <Main>
          <TextInput
            loading={loading}
            text={text}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
          />
          {result && <ResultList result={result} />}
        </Main>
      </div>
    );
  }

  handleChange = (text: string) => {
    this.setState({ text });
  };

  handleSubmit = () => {
    this.performRequest();
  };

  performRequest() {
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append('text', this.state.text);
    fetch('https://yasashii.overworks.jp/yasashiku.json', {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(result => {
        this.setState({ result, loading: false });
      });
  }
}
