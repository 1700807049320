import * as React from 'react';
import {
  Sentence,
  Segment,
  Morpheme,
  YomiPair,
  Status
} from '../models/result';
import { Card, Classes, Colors, Tooltip, Position } from '@blueprintjs/core';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  margin-top: 24px;
  .pt-popover-target,
  .pt-popover-wrapper {
    vertical-align: baseline;
  }
`;

const Rubied: React.StatelessComponent<{
  yomiPair: YomiPair;
}> = ({ yomiPair: [surface, yomi] }) => (
  <ruby>
    {surface}
    <rt>{yomi}</rt>
  </ruby>
);

const BeforeSegment: React.StatelessComponent<{
  segment: Segment;
}> = ({ segment }) => (
  <span>
    {segment.before.map((mo, i) =>
      mo.yomi_pairs.map(yomiPair => <Rubied yomiPair={yomiPair} />)
    )}
  </span>
);

const STATUS_TO_COLOR = {
  notfound: '#ff0000',
  propernoun: '#9400d3',
  changed: '#008000',
  note: '#0000ff',
  multi: '#0000ff',
  error: '#ff0000'
};

const AfterSegmentNote: React.StatelessComponent<{
  segment: Segment;
}> = ({ segment, children }) =>
  segment.note !== null ? (
    <Tooltip
      position={Position.TOP}
      content={
        (segment.mecabed_note && (
          <span>
            {segment.mecabed_note.map(mo =>
              mo.yomi_pairs.map(yomiPair => <Rubied yomiPair={yomiPair} />)
            )}
          </span>
        )) ||
        segment.note
      }
    >
      {children}
    </Tooltip>
  ) : (
    <span>{children}</span>
  );

const AfterSegment: React.StatelessComponent<{
  segment: Segment;
}> = ({ segment }) => (
  <span style={{ color: STATUS_TO_COLOR[segment.status] }}>
    <AfterSegmentNote segment={segment}>
      <span>
        {segment.after.map((mo, i) =>
          mo.yomi_pairs.map(yomiPair => <Rubied yomiPair={yomiPair} />)
        )}
      </span>
    </AfterSegmentNote>
  </span>
);

const SentenceCard: React.StatelessComponent<{
  sentence: Sentence;
}> = ({ sentence }) => {
  return (
    <StyledCard className={Classes.ELEVATION_1}>
      <p>{sentence.map(segment => <BeforeSegment segment={segment} />)}</p>
      <p>{sentence.map(segment => <AfterSegment segment={segment} />)}</p>
    </StyledCard>
  );
};

export default SentenceCard;
